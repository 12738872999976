import CryptoJS from 'crypto-js';
import { trackPurchaseFailed, trackPurchaseSuccess } from './datalayer-utils';
import { checkStoryType, getbase64url } from './utils';
import fetch from 'node-fetch';
import { USER_SUBSCRIBER, USER_SUBSCRIPTION } from '../../store/actions';
import { get } from 'lodash';
import MyInvoices from '../components/molecules/my-invoices/my-invoices';

const PAYMENT_TYPE = {
  simpl: 'simpl',
  paytm: 'paytm_auto_debit',
  razorpay: 'razorpay',
  razorpay_recurring: 'razorpay_recurring',
};

export const getUserjwtSignedToken = async (userInfo, secret) => {
  let header = {
    alg: 'HS256',
    typ: 'JWT',
  };
  let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  let encodedHeader = getbase64url(stringifiedHeader);
  let data = {
    id: getExternalUserId(userInfo?.userId),
    name: userInfo?.fullName || 'Reader',
    email: userInfo?.email,
    'login-phone-number': userInfo?.mobile,
  };
  let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  let encodedData = getbase64url(stringifiedData);

  let token = encodedHeader + '.' + encodedData;

  let signature = CryptoJS.HmacSHA256(token, secret);
  signature = getbase64url(signature);
  let signedToken = token + '.' + signature;
  return signedToken;
};
export const setUser = async (userInfo, accesstypeJwtSecret, metypeJwtSecret) => {
  const token = await getUserjwtSignedToken(userInfo, accesstypeJwtSecret);
  localStorage.setItem('UserAccesstypeJwtToken', token);
  const meToken = await getUserjwtSignedToken(userInfo, metypeJwtSecret);
  localStorage.setItem('UserMetypeJwtToken', meToken);
  return (
    global.AccessType &&
    global.AccessType.setUser({
      accesstypeJwt: token,
      name: userInfo?.fullName,
      emailAddress: userInfo?.email,
      mobileNumber: userInfo?.mobile,
    })
      .then((res) => {
        return res;
      })
      .catch((error) => console.error(error))
  );
};

export const unsetUser = (dispatch) => {
  dispatch({ type: USER_SUBSCRIBER, payload: false });
  return global.AccessType && global.AccessType.unsetUser();
};

export const getAssetPlan = (storyId) => {
  return global.AccessType.getAssetPlans({
    id: storyId,
    type: 'story',
  }).then((response) => {
    return response[0];
  });
};

export const getAllPlans = (story) => {
  const products = [];
  const macroPlans = [];
  const subscriptionPlansPromise =
    global.AccessType &&
    global.AccessType.getSubscriptionPlans().then((subscriptionPlans) => {
      subscriptionPlans.map((plan) => {
        products.push({
          name: plan.title,
          id: plan.id,
          price: plan.price_cents / 100,
          brand: story['story-template'] || 'text',
          category: 'Macro Plan',
          variant: checkStoryType(story),
          quantity: '1',
        });
        macroPlans.push({
          name: plan.title,
          id: plan.id,
          price: plan.price_cents / 100,
          brand: story['story-template'] || 'text',
          category: 'Macro Plan',
          variant: checkStoryType(story),
          quantity: '1',
        });
      });
    });

  const assetPlanPromise =
    global.AccessType &&
    getAssetPlan(story.id).then((res) => {
      products.push({
        name: res.duration_unit,
        id: res.id,
        price: res.price_cents / 100,
        brand: story['story-template'] || 'text',
        category: 'Micro Plan',
        variant: checkStoryType(story),
        quantity: '1',
      });
    });

  const promises = [subscriptionPlansPromise, assetPlanPromise];
  return Promise.all(promises).then(() => products);
};
export const getSubscriptionPlans = () => {
  return global.AccessType.getSubscriptionPlans().then((sp) => {
    let plans = sp.filter(
      (plan) =>
        plan.duration_length === 1 && plan.duration_unit === 'months' && plan.recurring === true
    );
    return plans[0];
  });
};

export const getSubscriptionParams = (plan, userInfo) => {
  return {
    type: 'standard',
    plan: {
      id: plan?.id,
      price_cents: plan?.price_cents,
      price_currency: plan?.price_currency,
      title: plan?.title,
    },
    payment: {
      payment_type:
        plan?.recurring === true ? PAYMENT_TYPE['razorpay_recurring'] : PAYMENT_TYPE['razorpay'],
      amount_cents: plan?.price_cents,
      amount_currency: plan?.price_currency,
    },
    metadata: {
      mobile_number: userInfo?.mobile,
    },
    additional_data: {
      phone_number: userInfo?.mobile,
      email: userInfo?.email,
    },
  };
};

export const getPaymentOptionsAndPay = async (
  subscriptionParams,
  paymentType = 'razorpay',
  onPaymentSuccess,
  onPaymentFailure
) => {
  try {
    return await global.AccessType.getPaymentOptions().then((paymentOptions) => {
      return paymentOptions[PAYMENT_TYPE[paymentType]]
        .proceed(subscriptionParams)
        .then(async (updatedPaymentOption) => {
          const subscription = updatedPaymentOption?.subscription;

          localStorage.removeItem('USER_SELECTED_PLAN');
          await onPaymentSuccess(subscription);
          await paymentSuccess(updatedPaymentOption);
          return updatedPaymentOption;

          //save updatedPaymentOption in which action will be updated to the next step.
        })
        .catch((e) => {
          onPaymentFailure();
          return paymentError(e);
        });
    });
  } catch (error) {
    return paymentError(error);
  }
};

export const paymentSuccess = (successResponse) => {
  const subscription = get(successResponse, ['subscription']);
  const invoice = get(subscription, ['invoices', '0'], '');
  const eventData = {
    subscription_id: get(subscription, ['id']),
    sequenced_invoice_number: get(invoice, ['sequenced_invoice_number'], ''),
    created_at: get(invoice, ['created_at'], ''),
    payment_type: get(subscription, ['payment_type']),
    article_title: get(subscription, ['dynamic_assets', '0', 'title']),
    article_price: get(subscription, ['plan_amount_cents'], 0) / 100,
    base_price: get(invoice, ['base_price'], ''),
    discount_amount: get(invoice, ['discount_details', 'discount_amount'], ''),
    discount_percentage: get(invoice, ['discount_details', 'discount_percentage'], ''),
    total_price: get(invoice, ['amount_after_discount_before_tax'], ''),
    CGST: get(invoice, ['invoice_taxes', 'CGST', 'amount'], ''),
    SGST: get(invoice, ['invoice_taxes', 'SGST', 'amount'], ''),
    payment_amount: get(subscription, ['payment_amount']),
  };
  trackPurchaseSuccess();
  return successResponse;
};

export const paymentError = (error) => {
  trackPurchaseFailed();
  return error;
};

export const checkForSubscription = async (dispatch) => {
  return (
    global.AccessType &&
    global.AccessType.getSubscriptions()
      .then(async (response) => {
        const purchasedSubscription = response.subscriptions.find(
          (subscription) =>
            subscription.subscription_type === 'standard' && subscription.status === 'active'
        );
        const otherSubscription = response.subscriptions
          .map(
            (subscription) =>
              subscription.subscription_type === 'standard' &&
              subscription.status !== 'active' &&
              subscription
          )
          .filter(Boolean);
        await setSubscriptionDetails(purchasedSubscription, otherSubscription, response, dispatch);
        return purchasedSubscription;
      })
      .catch((err) => {
        console.log('error====>>', err);
      })
  );
};

export const setSubscriptionDetails = async (
  purchasedSubscription,
  otherSubscription,
  response,
  dispatch
) => {
  const payPerArticle = response.subscriptions.find(
    (subscription) => subscription.subscription_type === 'dynamic_asset'
  );
  const additionalData = {
    payPerArticleStatus: payPerArticle ? 'purchased' : 'not purchased',
    access: payPerArticle?.dynamic_assets,
  };

  if (purchasedSubscription) {
    await dispatch({ type: USER_SUBSCRIBER, payload: true });
    await dispatch({
      type: USER_SUBSCRIPTION,
      payload: {
        active: purchasedSubscription,
        otherSubscription: otherSubscription,
        ...additionalData,
      },
    });
    const date = new Date();
    date.setTime(date.getTime() + 180 * 24 * 60 * 60 * 1000);
    return purchasedSubscription;
  } else {
    dispatch({ type: USER_SUBSCRIPTION, payload: null });
    dispatch({ type: USER_SUBSCRIBER, payload: false });
    return purchasedSubscription;
  }
};

export const downloadInvoice = (subscriptionId, invoiceId) => {
  try {
    return (
      global.AccessType &&
      global.AccessType.downloadInvoice(subscriptionId, invoiceId)
        .then((response) => {
          return response.blob();
        })
        .catch((error) => {
          console.log(error);
        })
    );
  } catch (err) {
    return err;
  }
};

export const getExternalUserId = (uid) => {
  return `np-${uid}`;
};

export const getSSORedirectUrl = async (url) => {
  let pageUrl = url?.href;
  let params = await new URL(url)?.searchParams;
  let source = await params?.get('source');
  let AMPredirectUrl = await params?.get('redirect-url');
  if (source === 'amp-story' && AMPredirectUrl) {
    if (AMPredirectUrl.indexOf('?') > -1) {
      AMPredirectUrl = AMPredirectUrl.substring(0, AMPredirectUrl.indexOf('?'));
    }
    pageUrl = AMPredirectUrl;
  } else {
    if (pageUrl.indexOf('?') > -1) {
      pageUrl = pageUrl.substring(0, pageUrl.indexOf('?'));
    }
  }
  return pageUrl;
};

export const getSSOLoginUrl = async (client_id, client_secret, redirectUrl, SSO_LOGIN_URL) => {
  let ssoUrl = await (SSO_LOGIN_URL || 'https://sso-dev.ndtvprofit.com/login');
  let pageUrl = await redirectUrl;

  return `${ssoUrl}?client_id=${client_id}&client_secret=${client_secret}&redirectUrl=${pageUrl}`;
};

export const getSSOLogoutUrl = async (client_id, client_secret, redirectUrl, SSO_LOGOUT_URL) => {
  let ssoUrl = await (SSO_LOGOUT_URL || 'https://sso-dev.ndtvprofit.com/logout');
  let pageUrl = await redirectUrl;
  localStorage.removeItem('UserAccesstypeJwtToken');
  localStorage.removeItem('UserMetypeJwtToken');
  return `${ssoUrl}?client_id=${client_id}&client_secret=${client_secret}&redirectUrl=${pageUrl}`;
};
