export const CLICK_KEY_HIGHLIGHT = 'CLICK_KEY_HIGHLIGHT';
export const REMOVE_CLICK_KEY_HIGHLIGHT = 'REMOVE_CLICK_KEY_HIGHLIGHT';
export const MEMBER_UPDATED = 'MEMBER_UPDATED';
export const HIDE_METER_BANNER = 'HIDE_METER_BANNER';
export const BQ_PAYWALLMETER_ENABLED = 'BQ_PAYWALLMETER_ENABLED';
export const BQ_HARDPAYWALL_ENABLED = 'BQ_HARDPAYWALL_ENABLED';
export const BQ_METER_LIMIT = 'BQ_METER_LIMIT';
export const BQ_STORY_PAGE_LOADED_STATUS = 'BQ_STORY_PAGE_LOADED_STATUS';
export const BQ_INCOGNITO_STATUS = 'BQ_INCOGNITO_STATUS';
export const BQ_USER_STATUS = 'BQ_USER_STATUS';
export const BQ_SEND_DMP_STATUS = 'BQ_SEND_DMP_STATUS';
export const BQ_CURRENT_SECTION_NAME = 'BQ_CURRENT_SECTION_NAME';
export const BQ_SUBSCRIPTION_DETAILS = 'BQ_SUBSCRIPTION_DETAILS';
export const BQ_STOCK_ALREADY_ADDED = 'BQ_STOCK_ALREADY_ADDED';
export const BQ_ENTITY_WATCHLIST_ADD_FROM_STORY = 'BQ_ENTITY_WATCHLIST_ADD_FROM_STORY';
export const BQ_ENTITY_WATCHLIST_ADD = 'BQ_ENTITY_WATCHLIST_ADD';
export const BQ_NOTIFICATION_LIST = 'BQ_NOTIFICATION_LIST';
export const BQ_STOCK_SOFT_REMOVE = 'BQ_STOCK_SOFT_REMOVE';
export const BQ_STOCK_WATCHLIST_UNDO = 'BQ_STOCK_WATCHLIST_UNDO';
export const BQ_STOCK_WATCHLIST_REMOVE = 'BQ_STOCK_WATCHLIST_REMOVE';
export const BQ_STOCK_WATCHLIST_GET = 'BQ_STOCK_WATCHLIST_GET';
export const BQ_STOCK_ALERTS_GET = 'BQ_STOCK_ALERTS_GET';
export const BQ_STOCK_ALERTS_UPDATE = 'BQ_STOCK_ALERTS_UPDATE';
export const BQ_STOCK_ALERTS_REMOVE = 'BQ_STOCK_ALERTS_REMOVE';
export const BQ_STOCK_ALERTS_ERROR = 'BQ_STOCK_ALERTS_ERROR';
export const BQ_STOCK_ALERTS_MEDIUM_UPDATE = 'BQ_STOCK_ALERTS_MEDIUM_UPDATE';
export const BQ_STOCK_NOTIFICATIONS_DISABLE = 'BQ_STOCK_NOTIFICATIONS_DISABLE';
export const BQ_STOCK_ALERT_SET = 'BQ_STOCK_ALERT_SET';
export const REMOVE_LOADER = 'REMOVE_LOADER';
export const SHOW_LOADER = 'SHOW_LOADER';
export const BQ_STOCK_NOTIFICATIONS_SET = 'BQ_STOCK_NOTIFICATIONS_SET';
export const BQ_WATCHLISTTAB = 'BQ_WATCHLISTTAB';
export const BQ_LIVE_TV_PLAYER_VALUE = 'BQ_LIVE_TV_PLAYER_VALUE';
export const BOTTOM_STORY_DATA = 'BOTTOM_STORY_DATA';
export const USER_SUBSCRIBER = 'USER_SUBSCRIBER';
export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const USER_PROFILE = 'USER_PROFILE';
export const USER_SUBSCRIPTION = 'USER_SUBSCRIPTION';
export const USER_SELECTED_PLAN = 'USER_SELECTED_PLAN';
export const USER_SELECTED_BOOKMARKS = 'USER_SELECTED_BOOKMARKS';
export const PUSHDOWN_AD = 'PUSHDOWN_AD';
