/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */

import { get } from 'lodash';
import {
  formatPublishedDate,
  getExtendedUserInfo,
  getUserDetails,
  getWhatsappOptIn,
} from './utils';
import { isMobile } from 'react-device-detect';

export function impression(impressions) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'impression',
      ecommerce: {
        currencyCode: 'INR',
        impressions,
      },
    });
}
export function sendProductView(products) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'productView',
      ecommerce: {
        detail: {
          actionField: { list: 'Subscription' },
          products: [{ ...products, source: window.location.pathname.substring(1) }],
        },
      },
    });
}
export function trackClick(products) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: 'Subscription' },
          products: [{ ...products, source: window.location.pathname.substring(1) }],
        },
      },
    });
}

export function sendProductDetail(products) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'productDetail',
      ecommerce: {
        detail: {
          actionField: {},
          products: [
            products.map((item) => ({ ...item, source: window.location.pathname.substring(1) })),
          ],
        },
      },
    });
}

export function trackAddToCart(product) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'INR',
        add: {
          actionField: { step: 1, option: 'Visa', list: 'Subscription' },
          products: [
            product.map((item) => ({ ...item, source: window.location.pathname.substring(1) })),
          ],
        },
      },
    });
}

export function trackCheckout(product) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Visa', list: 'Subscription' },
          products: [
            product.map((item) => ({ ...item, source: window.location.pathname.substring(1) })),
          ],
        },
      },
    });
}

export function trackPurchase(actionField, product) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'INR',
        purchase: {
          actionField,
          products: [
            product.map((item) => ({ ...item, source: window.location.pathname.substring(1) })),
          ],
        },
      },
    });
}

export function trackPurchaseSuccess() {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'purchaseSuccess',
      status: 'paymentSuccess',
    });
}

export function trackPurchaseFailed() {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'purchaseFailed',
      status: 'paymentFailed',
    });
}

export function trackUserInteraction(datalayerFields) {
  window && window.dataLayer && window.dataLayer.push(datalayerFields);
}

export async function trackArticleView(story, loginStatus) {
  if (window && window.dataLayer) {
    const {
      headline,
      alternative,
      id,
      'author-name': authorName,
      'published-at': publishedAt,
      'updated-at': updatedAt,
      tags,
    } = story;
    const publishedDateTime = formatPublishedDate(publishedAt);
    const [time, date] = publishedDateTime.split(',');
    const publishedTime = time.trim();
    const publishedDate = date.trim();
    const updatedAtDateTime = formatPublishedDate(updatedAt);
    const alternativeHeadline = alternative && alternative.headline ? alternative.headline : '';
    const articleTags = tags.length > 0 ? tags.map((t) => t.name).join('|') : 'NA';
    const accessTypeLevel = get(story, ['access-level-value'], '');
    const readTime = get(story, ['read-time'], null);
    const wordCount = get(story, ['word-count'], null);
    const storyTemplate = get(story, ['story-template'], story);
    const pageType =
      storyTemplate === 'video' || storyTemplate === 'ndtv-video'
        ? 'video-story'
        : storyTemplate === 'live-blog'
        ? 'live-story'
        : 'story';
    const browsingMode = 'default';
    const paragraphs = document.getElementsByTagName('p');
    const contentParaCount = paragraphs.length;
    const source =
      get(story, ['metadata', 'story-attributes', 'syndicatedfrom', '0']) || 'NDTV Profit';

    const authors = authorName;
    const category = window.location.pathname.substring(1).split('/')[0];
    const formattedCategory = category.replace(/[-\s]/g, '');
    const data = {
      event: 'page_property',
      page_title: headline || alternativeHeadline,
      page_template: pageType,
      authors: authors,
      agency: source,
      category: formattedCategory,
      cmsid: id,
      published_date: publishedDate,
      published_time: publishedTime,
      last_update_date: updatedAtDateTime,
      page_access: accessTypeLevel === 100 ? 'metered' : accessTypeLevel === 999 ? 'paid' : 'free',
      content_word_count: wordCount,
      content_para_count: contentParaCount,
      content_read_count: readTime,
      keywords: articleTags,
      browsing_platform: isMobile ? 'mobile' : 'desktop',
      browsing_mode: browsingMode,
      login_status: loginStatus,
      page_location: window.location.pathname,
    };
    window.dataLayer.push(data);
  }
}

export async function paywallWidgetImpression(pageType) {
  setTimeout(() => {
    if (!window || !window.dataLayer) return;

    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get('utm_source');
    const filteredParams = utmSource ? `?utm_source=${utmSource}` : '';
    const referrer = document.referrer;
    const currentDomain = window.location.hostname;
    const referrerValue = referrer && referrer.includes(currentDomain) ? '' : referrer;
    const referralTrafficVariables =
      filteredParams ||
      referrerValue ||
      localStorage.getItem('referral_traffic_variables') ||
      localStorage.getItem('referral_traffic_variables_oneachload') ||
      'direct';

    const pagePropertyEvent = window.dataLayer.find((item) => item.event === 'page_property');
    if (pagePropertyEvent) {
      const cmsid = pagePropertyEvent.cmsid;
      const paywallSource = pagePropertyEvent.page_access || 'widget impression';
      localStorage.setItem('cms_id', cmsid);
      localStorage.setItem('paywall_source', paywallSource);
    } else {
      console.log('page_property event not found in dataLayer');
    }

    const cmsid = localStorage.getItem('cms_id');
    const paywallSource = localStorage.getItem('paywall_source');

    let widgetType;
    if (pageType === 'section-page') {
      widgetType = window.scrollY < 1000 ? 'exclusive_section' : 'footer';
    } else if (pageType === 'story-page') {
      widgetType = window.scrollY > 1000 ? 'footer' : `article blocker widget | ${cmsid}`;
    } else if (pageType === 'home-page') {
      widgetType = window.scrollY < 3500 ? 'exclusive_hmpg' : 'footer';
    } else {
      widgetType = 'footer';
    }

    let paywallSourceLabel;
    if (widgetType === `article blocker widget | ${cmsid}`) {
      localStorage.setItem('article_blocker_widgetType', widgetType);
      switch (paywallSource) {
        case 'paid':
          paywallSourceLabel = 'hard paywall';
          break;
        case 'metered':
          paywallSourceLabel = 'metered paywall';
          break;
        default:
          paywallSourceLabel = 'widget impression';
      }
    } else {
      paywallSourceLabel = 'widget impression';
    }

    const data = {
      event: 'paywall_widget_impression',
      paywall_source: paywallSourceLabel,
      referral_traffic_variables: referralTrafficVariables,
      widget_type: widgetType,
    };

    localStorage.setItem('paywall_source', data.paywall_source);
    localStorage.setItem('referral_traffic_variables', data.referral_traffic_variables);
    localStorage.setItem('widget_type', data.widget_type);
  }, 2000);
}

export async function paywallCtaClick() {
  const urlParams = new URLSearchParams(window.location.search);
  const srcParam = urlParams.get('src');

  if (!srcParam || !window || !window.dataLayer) return;

  const defaultValues = {
    widgetType: 'unknown widget Type',
    ctaPosition: 'direct',
  };

  const mappings = {
    srcToCtaPosition: {
      subsc_excl: 'exclusives section',
      subs_hdr: 'header',
      subsc_ftr: 'footer',
      exclvs_subsc: 'homepageWidget',
      frpaywl: 'article blocker widget',
      hrdpaywl: 'article blocker widget',
    },
    srcToPaywallSource: {
      frpaywl: 'metered paywall',
      hrdpaywl: 'hard paywall',
    },
    ctaPositionToCtaText: {
      'exclusives section': 'Subscribe Now',
      'article blocker widget': 'Subscribe Now',
      header: 'Subscribe',
      footer: 'Subscribe Now',
      homepageWidget: 'Subscribe @ ₹48/week',
    },
  };

  const ctaPosition = mappings.srcToCtaPosition[srcParam] || defaultValues.ctaPosition;
  const ctaText = mappings.ctaPositionToCtaText[ctaPosition] || 'direct';
  const paywallSource = mappings.srcToPaywallSource[srcParam] || 'widget impression';

  const otherWidgetTypes =
    srcParam === 'subsc_excl'
      ? 'exclusive_section'
      : srcParam === 'subs_hdr'
      ? 'header'
      : srcParam === 'subsc_ftr'
      ? 'footer'
      : srcParam === 'exclvs_subsc'
      ? 'exclusive_hmpg'
      : defaultValues.widgetType;

  const widgetType =
    paywallSource === 'widget impression'
      ? otherWidgetTypes
      : localStorage.getItem('article_blocker_widgetType') || 'article blocker widget';

  const storyUrl = window.location.href;
  const queryParams = new URLSearchParams(storyUrl.split('?')[1]);

  const filteredParams = queryParams.has('utm_source') ? '?' + queryParams.toString() : '';

  const referrer = document.referrer;
  const currentDomain = window.location.hostname;

  const referrerValue =
    referrer && referrer.includes(currentDomain) ? 'direct' : referrer || 'direct';

  const referralTrafficVariables =
    filteredParams ||
    referrerValue ||
    localStorage.getItem('referral_traffic_variables') ||
    localStorage.getItem('referral_traffic_variables_oneachload') ||
    'direct';
  const data = {
    event: 'paywall_cta_click',
    referral_traffic_variables: referralTrafficVariables,
    cta_position: ctaPosition,
    cta_text: ctaText,
    paywall_source: paywallSource,
    widget_type: widgetType,
  };

  localStorage.setItem('referral_traffic_variables', referralTrafficVariables);
  localStorage.setItem('paywall_source', paywallSource);
  localStorage.setItem('cta_text', ctaText);
  localStorage.setItem('cta_position', ctaPosition);
  localStorage.setItem('widget_type', widgetType);

  window.dataLayer.push(data);
}

export function paywallPlanPageLoad() {
  if (!window || !window.dataLayer) return;

  const urlParams = new URLSearchParams(window.location.search);
  const srcParam = urlParams.get('src');
  const referrer = document.referrer;
  const currentDomain = window.location.hostname;

  const filteredParams = urlParams.has('utm_source') ? '?' + urlParams.toString() : '';
  const referrerValue =
    referrer && referrer.includes(currentDomain) ? 'direct' : referrer || 'direct';

  const referralTrafficVariables =
    filteredParams ||
    referrerValue ||
    localStorage.getItem('referral_traffic_variables') ||
    'direct';

  const mappings = {
    srcToCtaPosition: {
      subsc_excl: 'exclusives section',
      subs_hdr: 'header',
      subsc_ftr: 'footer',
      exclvs_subsc: 'homepageWidget',
      frpaywl: 'article blocker widget',
      hrdpaywl: 'article blocker widget',
    },
    srcToPaywallSource: {
      frpaywl: 'metered paywall',
      hrdpaywl: 'hard paywall',
    },
  };

  const { widgetType: defaultWidgetType, ctaPosition: defaultCtaPosition } = {
    widgetType: 'direct',
    ctaPosition: 'direct',
  };

  const otherWidgetTypes = mappings.srcToCtaPosition[srcParam] || defaultWidgetType;
  const paywallSource = mappings.srcToPaywallSource[srcParam] || 'widget impression';
  const widgetType =
    paywallSource === 'widget impression'
      ? otherWidgetTypes
      : localStorage.getItem('article_blocker_widgetType') || 'article blocker widget';
  const ctaPosition = mappings.srcToCtaPosition[srcParam] || defaultCtaPosition;

  const ctaText = {
    'exclusives section': 'Subscribe Now',
    'article blocker widget': 'Subscribe Now',
    header: 'Subscribe',
    footer: 'Subscribe Now',
    homepageWidget: 'Subscribe @ ₹48/week',
    direct: 'direct',
  }[ctaPosition];

  const data = {
    event: 'paywall_plan_page_load',
    referral_traffic_variables: referralTrafficVariables,
    paywall_source: localStorage.getItem('paywall_source') || 'direct',
    cta_text: ctaText,
    cta_position: ctaPosition,
    widget_type: widgetType || localStorage.getItem('widget_type') || defaultWidgetType,
  };

  Object.keys(data).forEach((key) => localStorage.setItem(key, data[key]));

  window.dataLayer.push(data);
}

export function paywallPlanSelectContinue(term) {
  if (!window || !window.dataLayer) return;
  const referralTrafficVariables = localStorage.getItem('referral_traffic_variables') || 'direct';
  const ctaText = localStorage.getItem('cta_text') || 'direct';
  const ctaPosition = localStorage.getItem('cta_position') || 'direct';
  const paywallSource = localStorage.getItem('paywall_source') || 'direct';
  const widgetType = localStorage.getItem('widget_type') || 'direct';
  const selectedPlanName = term?.name || 'NA';
  const selectedPlanId = term?.termId || 'NA';
  const finalAmount = term?.totalAmount || localStorage.getItem('final_amount') || 'NA';
  const formattedFinalAmount = finalAmount.replace(/INR\s*/i, '').replace(',', '');
  const finalAmountWithoutDecimals = parseInt(formattedFinalAmount.replace(/\.\d+$/, ''), 10);
  const currency = term?.chargeCurrency || 'INR';

  const data = {
    event: 'paywall_plan_select_continue',
    selected_plan_name: selectedPlanName,
    selected_plan_id: selectedPlanId,
    final_amount: finalAmountWithoutDecimals,
    currency: currency,
    referral_traffic_variables: referralTrafficVariables,
    cta_text: ctaText,
    cta_position: ctaPosition,
    paywall_source: paywallSource,
    widget_type: widgetType,
  };

  localStorage.setItem('referral_traffic_variables', data.referral_traffic_variables);
  localStorage.setItem('paywall_source', data.paywall_source);
  localStorage.setItem('widget_type', data.widget_type);
  localStorage.setItem('cta_position', data.cta_position);
  localStorage.setItem('cta_text', data.cta_text);
  localStorage.setItem('selected_plan_name', data.selected_plan_name);
  localStorage.setItem('selected_plan_id', data.selected_plan_id);
  localStorage.setItem('final_amount', data.final_amount);
  localStorage.setItem('currency', data.currency);
  window.dataLayer.push(data);
}

export function paywallCheckoutPopupCouponApplied() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || '';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || '';
    const final_amount = localStorage.getItem('final_amount') || '';
    const currency = localStorage.getItem('currency') || 'INR';
    const data = {
      event: 'paywall_checkout_popup_coupon_applied',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: 'NA',
    };

    localStorage.setItem('applied_coupon_code', data.applied_coupon_code);

    window.dataLayer.push(data);
  }
}

export function paywallCheckoutPaymentMethodContinue() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || 'NA';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || 'NA';
    const final_amount = localStorage.getItem('final_amount') || 'NA';
    const currency = localStorage.getItem('currency') || 'INR';
    const appliedCouponCode = localStorage.getItem('applied_coupon_code') || 'NA';
    const data = {
      event: 'paywall_checkout_payment_method_continue',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: appliedCouponCode,
      payment_method_name: 'UPI',
    };

    localStorage.setItem('payment_method_name', data.payment_method_name);

    window.dataLayer.push(data);
  }
}

export function paywallCheckoutPaymentCompleted() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || 'NA';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || 'NA';
    const final_amount = localStorage.getItem('final_amount') || 'NA';
    const currency = localStorage.getItem('currency') || 'INR';
    const appliedCouponCode = localStorage.getItem('applied_coupon_code') || 'NA';
    const paymentMethodName = localStorage.getItem('payment_method_name') || 'NA';
    const data = {
      event: 'paywall_checkout_popup',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: appliedCouponCode,
      payment_method_name: paymentMethodName,
    };

    window.dataLayer.push(data);
  }
}

export function paywallCheckoutPaymentFailed() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || 'NA';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || 'NA';
    const final_amount = localStorage.getItem('final_amount') || 'NA';
    const currency = localStorage.getItem('currency') || 'INR';
    const appliedCouponCode = localStorage.getItem('applied_coupon_code') || 'NA';
    const paymentMethodName = localStorage.getItem('payment_method_name') || 'NA';
    const data = {
      event: 'paywall_checkout_popup',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: appliedCouponCode,
      payment_method_name: paymentMethodName,
      failure_reason: 'gateway closed',
    };

    localStorage.setItem('failure_reason', data.failure_reason);

    window.dataLayer.push(data);
  }
}

export function paywallSubscriptionDone() {
  if (window && window.dataLayer) {
    const referralTrafficVariables = localStorage.getItem('referral_traffic_variables');
    const ctaText = localStorage.getItem('cta_text');
    const ctaPosition = localStorage.getItem('cta_position');
    const paywallSource = localStorage.getItem('paywall_source') || 'direct';
    const widgetType = localStorage.getItem('widget_type') || 'direct';
    const selectedPlanName = localStorage.getItem('selected_plan_name') || 'NA';
    const selectedPlanId = localStorage.getItem('selected_plan_id') || 'NA';
    const final_amount = localStorage.getItem('final_amount') || 'NA';
    const currency = localStorage.getItem('currency') || 'INR';
    const appliedCouponCode = localStorage.getItem('applied_coupon_code') || 'NA';
    const paymentMethodName = localStorage.getItem('payment_method_name') || 'NA';
    const data = {
      event: 'paywall_checkout_popup',
      selected_plan_name: selectedPlanName,
      selected_plan_id: selectedPlanId,
      final_amount: final_amount,
      currency: currency,
      referral_traffic_variables: referralTrafficVariables,
      cta_text: ctaText,
      cta_position: ctaPosition,
      paywall_source: paywallSource,
      widget_type: widgetType,
      applied_coupon_code: appliedCouponCode,
      payment_method_name: paymentMethodName,
    };

    window.dataLayer.push(data);
  }
}

export function loginWidgetImp() {
  if (!window || !window.dataLayer) return;
  const storyUrl = window.location.href;
  const queryParams = new URLSearchParams(storyUrl.split('?')[1]);
  const utmSource = queryParams.get('utm_source');
  const filteredParams = utmSource ? `?utm_source=${utmSource}` : '';

  const referrer = document.referrer;
  const currentDomain = window.location.hostname;
  const referrerValue =
    referrer && referrer.includes(currentDomain) ? 'direct' : referrer || 'direct';

  const referralTrafficVariables =
    filteredParams ||
    referrerValue ||
    localStorage.getItem('referral_traffic_variables') ||
    localStorage.getItem('referral_traffic_variables_oneachload') ||
    'direct';

  const paywallSource = localStorage.getItem('paywall_source') || 'direct';
  const widgetType = localStorage.getItem('widget_type') || 'direct';
  const ctaPosition = localStorage.getItem('cta_position') || 'direct';
  const ctaText = localStorage.getItem('cta_text') || 'direct';
  const loginPopupCase = localStorage.getItem('login_popup_case');

  const data = {
    event: 'login_widget_impression',
    login_popup_case: loginPopupCase,
    referral_traffic_variables: referralTrafficVariables,
    paywall_source: paywallSource,
    widget_type: widgetType,
    cta_text: ctaText,
    cta_position: ctaPosition,
  };

  const existingEventIndex = window.dataLayer.findIndex(
    (item) => item.event === 'login_widget_impression'
  );

  if (existingEventIndex !== -1) {
    window.dataLayer[existingEventIndex] = data;
  } else {
    window.dataLayer.push(data);
  }

  localStorage.setItem('referral_traffic_variables', data.referral_traffic_variables);
  localStorage.setItem('paywall_source', data.paywall_source);
  localStorage.setItem('widget_type', data.widget_type);
  localStorage.setItem('cta_position', data.cta_position);
  localStorage.setItem('cta_text', data.cta_text);
  localStorage.setItem('login_popup_case', data.login_popup_case);
}

export function loginCompleted() {
  if (!window || !window.dataLayer) return;
  const queryString = localStorage.getItem('referral_traffic_variables');
  const paywallSource = localStorage.getItem('paywall_source') || 'direct';
  const widgetType = localStorage.getItem('widget_type') || 'direct';
  const ctaPosition = localStorage.getItem('cta_position');
  const ctaText = localStorage.getItem('cta_text');
  const loginPopupCase = localStorage.getItem('login_popup_case');
  const loginSource = localStorage.getItem('login_source');

  const data = {
    event: 'login_completed',
    login_source: loginSource,
    login_type: 'signin',
    login_popup_case: loginPopupCase,
    referral_traffic_variables: queryString,
    paywall_source: paywallSource,
    widget_type: widgetType,
    cta_text: ctaText,
    cta_position: ctaPosition,
  };

  localStorage.setItem('login_type', data.login_type);
  window.dataLayer.push(data);
}

export function logOut() {
  if (window && window.dataLayer) {
    const data = {
      event: 'logOut',
    };
    window.dataLayer.push(data);
  }
}
