/* eslint-disable max-len */

export const PAGE_TYPE = Object.freeze({
  AUTHORS_PAGE: 'authors-page',
  HOME_PAGE: 'home-page',
  SECTION_PAGE: 'section-page',
  VIDEOS_PAGE: 'videos-page',
  LIVETV_PAGE: 'live-tv-page',
  PODCAST_PAGE: 'podcast-page',
  TAG_PAGE: 'tag-page',
  AUTHOR_PAGE: 'author-page',
  SEARCH_PAGE: 'search-page',
  STORY_PAGE: 'story-page',
  PLAYLIST_PAGE: 'playlist-page',
  CATALOG_PAGE: 'catalog-page',
  STORY_PUBLIC_PREVIEW_PAGE: 'story-public-preview-page',
  STORY_PREVIEW: 'story-preview',
  HOME_PREVIEW: 'home-preview',
  STATIC_PAGE: 'static-page',
  SUBSCRIPTION_PAGE: 'subscription-page',
  MYBQ_PAGE: 'my-account-page',
  VERIFY_EMAIL_PAGE: 'verify-email-page',
  SITEMAP_PAGE: 'sitemap',
  AMP_LOGIN: 'amp-login-page',
  NOT_SUPPORTED: 'not-supported',
  PWA_SHELL: 'shell',
  SECTION_VIDEO_PAGE: 'section-video-page',
  SUBSCRIBE_PUSH_NOTIFICATION: 'subscribe-push-notification',
  NOT_FOUND: 'not-found',
  LOGIN: 'user-login',
  SIGN_UP: 'user-sign-up',
  SIGN_UP_SUCCESS: 'thank-you',
  RESEARCH_REPORTS_PAGE: 'research-report-page',
  SUBSCRIPTION_PAGE_IPO: 'subscription-page-ipo',
  SUBSCRIPTION_PAGE_DIWALI: 'subscription-page-diwali',
  BQ_EDGE_EVENTS_DIWALI: 'bq-edge-events-diwali',
  FEED_COUNTER_DASHBOARD: 'feed-counter-dashboard',
  EDITORIAL_CODE_OF_CONDUCT: 'editorial-code-of-conduct',
  MARKET_PAGE: 'market-page',
  STOCK_PAGE: 'stock-page',
  INDIAN_INDICES_PAGE: 'indian-indices-page',
  SECTORAL_INDICES_PAGE: 'sectoral-indices-page',
  CORPORATE_ACTIONS_PAGE: 'corporate-actions-page',
  HISTORICAL_RETURNS_PAGE: 'historical-returns-page',
  STOCKS_IN_INDICES_PAGE: 'stocks-in-indices-page',
  SECTORAL_STOCKS_IN_INDICES_PAGE: 'sectoral-stocks-in-indices-page',
  FUTURES_PAGE: 'futures-page',
  OPEN_INTEREST_PAGE: 'open-interest-page',
  GREATEST_PREMIUM_PAGE: 'greatest-premium-page',
  FUTURES_ROLLOVER_PAGE: 'futures-rollover-page',
  FUTURES_SHORT_COVERING_PAGE: 'futures-short-covering-page',
  FUTURES_MOST_ACTIVE_CONTRACTS_PAGE: 'futures-most-active-contracts-page',
  FUTURES_SECTORAL_MOVEMENTS_PAGE: 'futures-sectoral-movements-page',
  FUTURES_FILTER_PAGE: 'futures-filter-page',
  OPTIONS_PAGE: 'options-page',
  OPTIONS_MOST_ACTIVE_INDEX: 'options-most-active-index',
  OPTIONS_MOST_ACTIVE_STOCK: 'options-most-active-stock',
  OPTIONS_TOP_OPEN_INDEX: 'options-top-open-index',
  OPTIONS_TOP_OPEN_STOCK: 'options-top-open-stock',
  OPTIONS_OPEN_INTEREST_BREAKUP: 'options-open-interest-breakup',
  OPTIONS_OPEN_INTEREST_CHANGE: 'options-open-interest-change',
  OPTIONS_OI_LAST_EXPIRY: 'options-oi-last-expiry',
  OPTIONS_PUT_CALL_RATIO: 'options-put-call-ratio',
  BUSINESS_CALCULATORS: 'calculators',
  HOME_LOAN: 'home-loan',
  CAR_LOAN: 'car-loan',
  EDUCATION_LOAN: 'education-loan',
  PERSONAL_LOAN: 'personal-loan',
  FIXED_DEPOSIT: 'fixed-deposit',
  RECURRING_DEPOSIT: 'recurring-deposit',
  PUBLIC_PROVIDENT_FUND: 'public-provident-fund',
  SIP: 'sip',
  INCOME_TAX: 'income-tax',
  BUDGET_PAGE: 'budget-page',
  BREAKOUT_ALERTS_PAGE: 'breakout-alerts',
  HOME_PAGE_TEST: 'home-page-test',
  SECTION_PAGE_TEST: 'section-page-test',
  STORY_PAGE_TEST: 'story-page-test',

  // INDIVIDUAL MARKET WIDGETS
  INDICES_WIDGET: 'widget-page-indices',
  FUTURES_GRAPH_WIDGET: 'widget-page-futures-graph',
  ADVANCES_DECLINES_WIDGET: 'widget-page-advances-declines',
  STOCK_STATS_WIDGET: 'widget-page-stock-stats',
  MARKETS_NEWS_WIDGET: 'widget-page-markets-news',

  // FULL PAGE WIDGET
  LIVE_TV_WIDGET: 'widget-page-live-tv',
  MARKETS_FULL_WIDGET: 'widget-page-markets',
  STOCK_FULL_WIDGET: 'widget-page-stock',
  FUTURES_CONTRACT_FULL_WIDGET: 'widget-page-futures-contract',
  NDTV_MARKET_WIDGET: 'widget-ndtv-market',
  DAILYHUNT_MARKET_WIDGET: 'widget-dailyhunt-market',
  STOCKS_IN_FOCUS_FULL_WIDGET: 'widget-stocks-in-focus',
  STATE_OF_THE_ECONOMY_FULL_WIDGET: 'widget-state-of-the-economy',
  OPTIONS_CHAIN_FULL_WIDGET: 'widget-page-option-chain',
  EQ_ALL_FULL_WIDGET: 'widget-paget-equities-all',
  STOCK_IN_INDICES_FULL_WIDGET: 'widget-page-equities-stock-indices',
  HISTORICAL_RETURNS_FULL_WIDGET: 'widget-page-equities-historical-returns',
  SECTORAL_INDICES_FULL_WIDGET: 'widget-page-equities-sectoral-indices',
  SECTORAL_STOCK_IN_INDICES_FULL_WIDGET: 'widget-page-equities-sectoral-stock-indices',
  CALENDER_EVENTS_FULL_WIDGET: 'widget-page-equities-calender-events',
  SHORT_COVERING_FULL_WIDGET: 'widget-page-futures-short-covering',
  MOST_ACTIVE_CONTRACTS_FULL_WIDGET: 'widget-page-futures-most-active-contracts',
  SECTORAL_MOVEMENTS_FULL_WIDGET: 'widget-page-futures-sectoral-movements',
  MOST_ACTIVE_INDEX_OPTIONS_FULL_WIDGET: 'widget-page-options-most-active-index-options',
  MOST_ACTIVE_STOCK_OPTIONS_FULL_WIDGET: 'widget-page-options-most-active-stock-options',
  TOP_TEN_INT_INDEX_OPTIONS_FULL_WIDGET: 'widget-page-options-top-ten-interest-index-options',
  TOP_TEN_INT_STOCK_OPTIONS_FULL_WIDGET: 'widget-page-options-top-ten-interest-stock-options',
  OPEN_INTEREST_BREAKUP_FULL_WIDGET: 'widget-page-open-interest-breakup-full-widget',
  FUTURES_OI_CHANGE_SINCE_LAST_EXPIRY: 'widget-page-futures-oi-change-since-last-expiry',
  OPTIONS_OI_CHANGE_SINCE_LAST_EXPIRY: 'widget-page-options-oi-change-since-last-expiry',
  PUT_CALL_RATIO_FULL_WIDGET: 'widget-page-put-call-ratio',
  TOPIC_FULL_WIDGET: 'widget-page-topic',
  GREATEST_PREMIUM_FULL_WIDGET: 'widget-page-greatest-premium',
  FUTURES_ROLLOVER_FULL_WIDGET: 'widget-page-rollover',
  OPEN_INTEREST_FULL_WIDGET: 'widget-page-open-interest',
  BREAKOUT_ALERTS_WIDGET: 'widget-breakout-alerts',
  BUDGET_STOCKS_IN_FOCUS_LIVE: 'widget-stocks-in-focus-live',
  BUDGET_IMPACT_WIDGET: 'widget-budget-impact',
  KEY_HIGHLIGHTS_WIDGET: 'widget-key-highlights',
});

export const DATE_FORMATS = Object.freeze({
  DATE_TIME: 'MMMM dd yyyy, h:mm aa',
  DATE_TIME_INDIAN: 'dd MMMM yyyy, h:mm aa',
  DATE_TIME_INDIAN_SHORT_MONTH: 'dd MMM yyyy, h:mm aa',
  DATE_TIME_SHORT_MONTH: 'MMM dd yyyy, h:mm aa',
  HOUR_MINUTE: 'h:mm a',
  MONTH_DATE_HOUR_MINUTE: 'MMMM dd, h:mm a',
  HOUR_MINUTE_MONTH_DATE: 'h:mm aa, MMMM dd',
  HOUR_MINUTE_DATE_MONTH_YEAR: 'h:mm aa, dd MMM yyyy',
  MONTH_DATE_HOUR_MINUTE_MERIDIEM: 'MMMM dd, h:mm aa',
  HOUR_MINUTE_MERIDIEM: 'hh:mm aa',
  DATE_FULLMONTH_YEAR: 'dd MMMM yyyy',
  MONTH_DATE_YEAR: 'MMM dd, yyyy',
  DATE_MONTH_YEAR: 'dd MMM yyyy',
  DATE_TIME_ZONE: 'yyyy-MM-dd HH:mm:ssxxx',
});

export const EXTERNAL = {
  BQ_BRAND_STUDIO: 'https://ndtvprofitbrandstudio.com',
  BLOOMBERG_QUINT: 'https://www.ndtvprofit.com',
  BLOOMBERG: 'https://www.bloomberg.com',
  QUINT: 'https://thequint.com',
  NDTV_PROFIT: 'https://www.ndtvprofit.com',
};

export const PATH = Object.freeze({
  MYBQ_PAGE: '/my-account',
  MYBQ_PAGE_TABS: '/my-account/:tabs',
  SUBSCRIPTION_PAGE: '/subscription',
  BREAKOUT_ALERTS_PAGE: '/budget/breakout-alerts',
  SITEMAP_PAGE: '/sitemap',
  AUTHOR_PAGE: '/author/:id/:slug',
  AUTHORS_PAGE: '/authors',
  TAG_PAGE: '/topic/:tagSlug',
  SEARCH_PAGE: '/search',
  MYBQ_COUPONS: '/coupons',
  MYBQ_PERSONALISED: '/myBQ',
  MYBQ_LOYALTY: '/loyalty',
  MYBQ_STOCK_WATCHLIST: '/my-watchlist',
  MYBQ_ACCOUNT: '/account',
  MYBQ_NEWSLETTER: '/newsletter',
  MYACCOUNT_SUBSCRIPTION: '/subscription',
  MYACCOUNT_PROFILE: '/profile',
  MYACCOUNT_BOOKMARKS: '/bookmarks',
  MYACCOUNT_NEWSLETTER: '/newsletter',
  MYACCOUNT_WATCHLIST: '/watchlist',
  SUBSCRIBE_PUSH_NOTIFICATION: '/subscribe-push-notification',
  LOGIN: '/user-login',
  SIGN_UP: '/user-sign-up',
  SIGN_UP_SUCCESS: '/reset-password',
  HOME: '/',
  EXCLUSIVE: '/exclusive',
  BQ_TRENDING: '/trending',
  TODAYS_LATEST: '/the-latest',
  BUSINESS: '/business',
  MARKET_PAGE: '/markets',
  STOCK_PAGE: '/stock/:id/:stockName',
  INDIAN_INDICES_PAGE: '/markets/equities/all',
  SECTORAL_INDICES_PAGE: '/markets/equities/sectoral-indices',
  CORPORATE_ACTIONS_PAGE: '/markets/equities/calendar-events',
  HISTORICAL_RETURNS_PAGE: '/markets/equities/historical-returns',
  STOCKS_IN_INDICES_PAGE: '/markets/equities/stocks-in-indices',
  SECTORAL_STOCKS_IN_INDICES_PAGE: '/markets/equities/sectoral-stocks-in-indices',
  FUTURES_PAGE: '/markets/futures-contract',
  OPEN_INTEREST_PAGE: '/markets/futures-contract/open-interest',
  GREATEST_PREMIUM_PAGE: '/markets/futures-contract/greatest-premium-and-discount',
  FUTURES_ROLLOVER_PAGE: '/markets/futures-contract/rollover',
  FUTURES_SHORT_COVERING_PAGE: '/markets/futures-contract/short-covering',
  FUTURES_MOST_ACTIVE_CONTRACTS_PAGE: '/markets/futures-contract/most-active-contracts',
  FUTURES_SECTORAL_MOVEMENTS_PAGE: '/markets/futures-contract/sectoral-movements',
  FUTURES_FILTER_PAGE: '/markets/futures-contract/:security',
  OPTIONS_PAGE: '/markets/option-chain',
  OPTIONS_MOST_ACTIVE_INDEX: '/markets/option-chain/most-active-index-options',
  OPTIONS_MOST_ACTIVE_STOCK: '/markets/option-chain/most-active-stock-options',
  OPTIONS_TOP_OPEN_INDEX: '/markets/option-chain/top-open-interest-index-options',
  OPTIONS_TOP_OPEN_STOCK: '/markets/option-chain/top-open-interest-stock-options',
  OPTIONS_OPEN_INTEREST_BREAKUP: '/markets/option-chain/open-interest-breakup',
  OPTIONS_OPEN_INTEREST_CHANGE:
    '/markets/option-chain/futures-open-interest-change-since-last-expiry',
  OPTIONS_OI_LAST_EXPIRY: '/markets/option-chain/options-open-interest-change-since-last-expiry',
  OPTIONS_PUT_CALL_RATIO: '/markets/option-chain/put-call-ratio',
  ECONOMY_FINANCE: '/economy-finance',
  LAW_POLICY: '/law-and-policy',
  VIDEOS: '/videos',
  PERSONAL_FINANCE: '/personal-finance',
  OPINION: '/opinion',
  POLITICS: '/politics',
  BQ_LEARNING: '/bqlearning',
  TECHNOLOGY: '/technology',
  PURSUIT: '/pursuits',
  ABOUT_US: '/about-us',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  RSS: '/stories.rss',
  RESEARCH_REPORTS_PAGE: '/bq-blue-subscription/research-report-page',
  SUBSCRIPTION_PAGE_IPO: '/bq-blue-subscription/ipo',
  SUBSCRIPTION_PAGE_DIWALI: '/bq-blue-subscription/diwali',
  BQ_EDGE_EVENTS_DIWALI: '/bq-edge-events/diwali',
  AUTHORS: '/authors',
  EDITORIAL_CODE_OF_CONDUCT: '/editorial-code-of-conduct',
  BUSINESS_CALCULATORS: '/calculators',
  HOME_LOAN: '/calculators/home-loan',
  CAR_LOAN: '/calculators/car-loan',
  EDUCATION_LOAN: '/calculators/education-loan',
  PERSONAL_LOAN: '/calculators/personal-loan',
  FIXED_DEPOSIT: '/calculators/fixed-deposit',
  RECURRING_DEPOSIT: '/calculators/recurring-deposit',
  PUBLIC_PROVIDENT_FUND: '/calculators/public-provident-fund',
  SIP: '/calculators/sip',
  INCOME_TAX: '/calculators/income-tax',
  PODCAST_PAGE: '/podcast-page',

  BQ_BRAND_STUDIO: EXTERNAL.BQ_BRAND_STUDIO,
  CALCULATORS: '/calculators',
  WEB_STORIES: '/web-stories',
  SECTION_PAGE_TEST: '/lite/section-page-test',
  STORY_PAGE_TEST: '/lite/:storySlug',

  // INDIVIDUAL MARKET WIDGETS
  INDICES_WIDGET: '/markets/widget/indices',
  FUTURES_GRAPH_WIDGET: '/markets/widget/futures-graph',
  ADVANCES_DECLINES_WIDGET: '/markets/widget/advances-declines',
  STOCK_STATS_WIDGET: '/markets/widget/stock-stats',
  MARKETS_NEWS_WIDGET: '/markets/widget/markets-news',

  // FULL PAGE WIDGETS
  LIVE_TV_WIDGET: '/widget/live-tv',
  MARKETS_FULL_WIDGET: '/widget/markets',
  STOCK_FULL_WIDGET: '/widget/stock/:id/:stockName',
  FUTURES_CONTRACT_FULL_WIDGET: '/widget/futures-contract',
  NDTV_MARKET_WIDGET: '/widget/markets/stocks-in-focus',
  DAILYHUNT_MARKET_WIDGET: '/widget/markets/indices',
  STOCKS_IN_FOCUS_FULL_WIDGET: '/widget/stocks-in-focus',
  STATE_OF_THE_ECONOMY_FULL_WIDGET: '/widget/state-of-the-economy',
  OPTIONS_CHAIN_FULL_WIDGET: '/widget/option-chain',
  EQ_ALL_FULL_WIDGET: '/widget/equities/all',
  STOCK_IN_INDICES_FULL_WIDGET: '/widget/equities/stocks-in-indices',
  HISTORICAL_RETURNS_FULL_WIDGET: '/widget/equities/historical-returns',
  SECTORAL_INDICES_FULL_WIDGET: '/widget/equities/sectoral-indices',
  SECTORAL_STOCK_IN_INDICES_FULL_WIDGET: '/widget/equities/sectoral-stocks-in-indices',
  CALENDER_EVENTS_FULL_WIDGET: '/widget/equities/calendar-events',
  SHORT_COVERING_FULL_WIDGET: '/widget/futures-contract/short-covering',
  MOST_ACTIVE_CONTRACTS_FULL_WIDGET: '/widget/futures-contract/most-active-contracts',
  SECTORAL_MOVEMENTS_FULL_WIDGET: '/widget/futures-contract/sectoral-movements',
  MOST_ACTIVE_INDEX_OPTIONS_FULL_WIDGET: '/widget/option-chain/most-active-index-options',
  MOST_ACTIVE_STOCK_OPTIONS_FULL_WIDGET: '/widget/option-chain/most-active-stock-options',
  TOP_TEN_INT_INDEX_OPTIONS_FULL_WIDGET: '/widget/option-chain/top-open-interest-index-options',
  TOP_TEN_INT_STOCK_OPTIONS_FULL_WIDGET: '/widget/option-chain/top-open-interest-stock-options',
  OPEN_INTEREST_BREAKUP_FULL_WIDGET: '/widget/option-chain/open-interest-breakup',
  FUTURES_OI_CHANGE_SINCE_LAST_EXPIRY:
    '/widget/option-chain/futures-open-interest-change-since-last-expiry',
  OPTIONS_OI_CHANGE_SINCE_LAST_EXPIRY:
    '/widget/option-chain/options-open-interest-change-since-last-expiry',
  PUT_CALL_RATIO_FULL_WIDGET: '/widget/option-chain/put-call-ratio',
  TOPIC_FULL_WIDGET: '/widget/topic/:tagSlug',
  GREATEST_PREMIUM_FULL_WIDGET: '/widget/futures-contract/greatest-premium-and-discount',
  FUTURES_ROLLOVER_FULL_WIDGET: '/widget/futures-contract/rollover',
  OPEN_INTEREST_FULL_WIDGET: '/widget/futures-contract/open-interest',
  BREAKOUT_ALERTS_WIDGET: '/widget/breakout-alerts',
  BUDGET_STOCKS_IN_FOCUS_LIVE: '/widget/stocks-in-focus-live',
  BUDGET_IMPACT_WIDGET: '/widget/budget-impacts',
  KEY_HIGHLIGHTS_WIDGET: '/widget/key-highlights',
});

export const EVENT_NAMES = Object.freeze({
  PURCHASE_MACRO: 'Purchase_Macro_Success',
  PURCHASE_MICRO: 'Purchase_Micro_Success',
});

export const STRUCTURED_DATA = {
  organization: {
    name: 'NDTV Profit',
    url: 'https://www.ndtvprofit.com/',
  },
  website: {
    url: 'https://www.ndtvprofit.com',
    searchpath: 'search?q={q}',
    queryinput: 'required name=q',
  },
  enableLiveBlog: true,
  enableNewsArticle: 'withoutArticleSchema',
  stripHtmlFromArticleBody: true,
  enableVideo: true,
};

export const BLOOMBERGQUINT_FOOTER_LINKS = {
  header: 'NDTV Profit',
  links: [
    {
      name: 'Trending Now',
      href: PATH.BQ_TRENDING,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Exclusives',
      href: PATH.EXCLUSIVE,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: `Today's Latest`,
      href: PATH.TODAYS_LATEST,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Business',
      href: PATH.BUSINESS,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Markets',
      href: PATH.MARKET_PAGE,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Nifty Futures',
      href: PATH.FUTURES_PAGE,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Economy & Finance',
      href: PATH.ECONOMY_FINANCE,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Law & Policy',
      href: PATH.LAW_POLICY,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Videos',
      href: PATH.VIDEOS,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Personal Finance',
      href: PATH.PERSONAL_FINANCE,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Opinion',
      href: PATH.OPINION,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Politics',
      href: PATH.POLITICS,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Learning',
      href: PATH.BQ_LEARNING,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Technology',
      href: PATH.TECHNOLOGY,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Pursuits',
      href: PATH.PURSUIT,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Brand Studio',
      href: PATH.BQ_BRAND_STUDIO,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Calculators',
      href: PATH.CALCULATORS,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Web Stories',
      href: PATH.WEB_STORIES,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  ],
};
export const SOCIAL_SHARE = {
  TELEGRAM: 'https://t.me/ndtvprofitnews',
  WEBSITE_NOTIFICATION: 'https://www.ndtvprofit.com/subscribe-push-notification',
  NEWSLETTER: '/my-account/newsletter',
  PODCAST: 'https://www.ndtvprofit.com/podcasts/all-you-need-to-know/414759',
  WHATSAPP: 'https://www.whatsapp.com/channel/0029Va9NyUD6RGJPJbs1Z01x',
  LINKEDIN: 'https://www.linkedin.com/company/ndtvprofit',
  YOUTUBE: 'https://www.youtube.com/channel/UC3uJIdRFTGgLWrUziaHbzrg',
  TWITTER: 'https://twitter.com/NDTVProfitIndia',
  INSTAGRAM: 'https://www.instagram.com/ndtvprofit',
  FACEBOOK: 'https://m.facebook.com/NDTVProfit',
  GOOGLE_NEWS:
    'https://news.google.com/publications/CAAqBwgKMKr0kAsw9JylAw?hl=en-IN&gl=IN&ceid=IN%3Aen',
};
export const GET_REG_UPDATES_FOOTER_LINKS = {
  header: 'Stay Updated',
  links: [
    {
      name: 'Newsletter',
      href: SOCIAL_SHARE.NEWSLETTER,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Telegram',
      href: SOCIAL_SHARE.TELEGRAM,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Podcasts',
      href: SOCIAL_SHARE.PODCAST,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: 'Whatsapp',
      href: SOCIAL_SHARE.WHATSAPP,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  ],
};

export const SOCIAL_ICONS = [
  {
    name: 'LinkedIn',
    url: SOCIAL_SHARE.LINKEDIN,
    icon_code: 'icon-ic_linkedin_black',
  },
  {
    name: 'Youtube',
    url: SOCIAL_SHARE.YOUTUBE,
    icon_code: 'icon-ic_youtube',
  },
  {
    name: 'Twitter',
    url: SOCIAL_SHARE.TWITTER,
    icon_code: 'icon-ic_twitter',
  },
  {
    name: 'Instagram',
    url: SOCIAL_SHARE.INSTAGRAM,
    icon_code: 'icon-ic_instagram',
  },
  {
    name: 'Telegram',
    url: SOCIAL_SHARE.TELEGRAM,
    icon_code: 'icon-ic_telegram',
  },
  {
    name: 'Facebook',
    url: SOCIAL_SHARE.FACEBOOK,
    icon_code: 'icon-ic_facebook',
  },
  {
    name: 'NewsLetter',
    url: SOCIAL_SHARE.NEWSLETTER,
    icon_code: 'icon-ic_mail',
  },
  {
    name: 'WhatsApp',
    url: SOCIAL_SHARE.WHATSAPP,
    icon_code: 'icon-ic_whatsapp',
  },
  {
    name: 'Google News',
    url: SOCIAL_SHARE.GOOGLE_NEWS,
    icon_code: 'icon-ic_google-news',
  },
];

export const COPYRIGHT_LINKS = [
  {
    name: 'About us',
    href: PATH.ABOUT_US,
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  {
    name: 'Terms of Use',
    href: PATH.TERMS_OF_USE,
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  {
    name: 'Privacy Policy',
    href: PATH.PRIVACY_POLICY,
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  {
    name: 'Sitemap',
    href: PATH.SITEMAP_PAGE,
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  {
    name: 'RSS',
    href: PATH.RSS,
    target: '_blank',
    rel: 'noopener noreferrer',
  },
];

export const CONTACT = {
  SUPPORT_ID: 'profitsupport@ndtv.com',
  EDITOR_ID: 'profiteditor@ndtv.com',
  SALES_ID: 'profitsales@ndtv.com',
  CONTACT_ID: 'Get In Touch',
};

export const STRINGS = {
  GET_YEAR: `${new Date().getFullYear()}`,
  COPYRIGHT_TEXT: `${new Date().getFullYear()} NDTV Profit. All Rights Reserved`,
  NEW_COPYRIGHT_TEXT: `NDTV Profit. All Rights Reserved`,
  DISCLAIMER_TEXT: `The BLOOMBERG trademark and service mark is owned by Bloomberg Finance L.P., a Delaware limited partnership,
  or its subsidiaries. Bloomberg does not own or control the platform on which this mark appears and its appearance here is pursuant to a trademark
   license agreement and does not imply any endorsement of the platform or its owners. For the avoidance of doubt, any content on this platform
   that does not carry a “Bloomberg” byline was not authored by, and is not controlled by,
  Bloomberg nor does it reflect Bloomberg’s views or opinions.`,
  SUBSCRIBE: 'Subscribe',
  SUBSCRIBE_NOW: 'Subscribe Now',
  SUBSCRIBE_WEEKLY: 'Subscribe @ ₹48/week',
  EXPLORE_PLANS: 'Explore Plans',
  FOLLOW_US: 'Follow Us',
  NOW_PLAYING: 'NOW PLAYING',
  MUST_WATCH_TITLE: 'MUST WATCH',
  MUST_WATCH_SUB_TITLE: 'New Webcasts from Business Experts Everyday',
  EDITORIAL_FEEDBACK: 'Editorial Feedback',
  SALES_QUERIES: 'Sales Queries',
  SUBSCRIPTION_QUERIES: 'Subscription Queries',
  MESSAGE_US: 'Message Us',
  INQUIRE_NOW: 'Inquire Now',
  GET_IN_TOUCH: 'Get in Touch',
  SALES: 'Sales',
  JOIN: 'Join our',
  FOR_ANY_COMPLAINTS: 'For any Complaints',
  OFFICIAL_CHANNELS: 'Official Channels',
  RESEARCH_REPORTS_SUBTITLE:
    'NDTV Profit special research section collates quality and in-depth equity and economy research reports from across India’s top brokerages, asset managers and research agencies.',
  LEGAL_REPORTS_SUBTITLE:
    'NDTV Profit special Legal Reports section collates quality and in-depth equity and economy legal reports from across India’s top brokerages, asset managers and research agencies.',
  REACH_OUT_TO_US: 'Reach out to us',
  WATCH_LIVE: 'Watch LIVE',
  STORY_IMAGE_PLACEHOLDER:
    'https://qtstage-01.gumlet.io/bloombergquint/import/ndtv_profit_placeholder___english.png',
  WEB_STORY_IMAGE_PLACEHOLDER:
    'https://qtstage-01.gumlet.io/bloombergquint/import/ndtv_profit_placeholder___web_stories___3_4.png',
  VIDEOS_PAGE_PLACEHOLDER:
    'https://images.assettype.com/bloombergquint/import/video_placeholder.png?format=webp&w=744',
};

export const ResearchReportAuthors = [
  'ICICI Securities',
  'Motilal Oswal',
  'Centrum Broking',
  'ICICI Direct',
  'IDBI Capital',
  'Prabhudas Lilladher',
  'Dolat Capital',
];

export const TYPEFORM_LINKS = {
  CONTACT_FORM: 'https://bqprime.typeform.com/to/SFYms9mY',
  STUDENT_SUB_FORM: 'https://bqprime.typeform.com/to/yJQBVn9N',
  TEAM_SUB_FORM: 'https://bqprime.typeform.com/to/pydgIuG0',
};

export const GOOGLE_FORM_LINKS = {
  COMPLAINTS_REDRESSAL_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLScWLKZ-4iEKjpYvXt4oP5XGIVCL3rhyIBV7Ug98atvxsetKaw/viewform',
};

export const FOOTER_CONTACT_US = {
  CONTACT_ID: 'Get In Touch',
  CONTACT_LIST: [
    {
      link: `mailto:${CONTACT.EDITOR_ID}`,
      path: '/icons/editor.png',
      title: 'Editorial Feedback',
      email: 'profiteditor@ndtv.com',
    },
    {
      link: `mailto:${CONTACT.SUPPORT_ID}`,
      path: '/icons/subscription.png',
      title: 'Subscription Queries',
      email: 'profitsupport@ndtv.com',
    },
    {
      link: `mailto:${CONTACT.SALES_ID}`,
      path: '/icons/sales.png',
      title: 'Sales',
      email: 'profitsales@ndtv.com',
    },
    {
      link: 'https://www.ndtv.com/convergence/ndtv/new/complaintform.aspx',
      path: '/icons/complaints.png',
      title: 'For Any Complaints',
      email: 'Get In Touch',
    },
  ],
};

export const SUBSCRIBER_FEATURES = [
  {
    id: 1,
    title: 'Access to',
    desc: 'Bloomberg',
    url: '/icons/message_star.svg',
    link: '/icons/star_profit_white.svg',
    link2: '/icons/star_profit_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 2,
    title: '20,000+',
    desc: 'Research Reports',
    url: '/icons/file_chart.svg',
    link: '/icons/file_chart_white.svg',
    link2: '/icons/file_chart_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 3,
    title: 'Ad-Lite',
    desc: 'Experience',
    url: '/icons/cancel.svg',
    link: '/icons/cancel_white.svg',
    link2: '/icons/cancel_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 4,
    title: 'NDTV Profit',
    desc: 'Exclusive Stories',
    url: '/icons/star_profit.svg',
    link: '/icons/message_star_white.svg',
    link2: '/icons/message_star_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 5,
    title: 'Curated',
    desc: 'Newsletters',
    url: '/icons/email_lock.svg',
    link: '/icons/email_lock_white.svg',
    link2: '/icons/email_lock_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 6,
    title: 'Priority Pass',
    desc: 'to Special Events',
    url: '/icons/seat.svg',
    link: '/icons/seat_white.svg',
    link2: '/icons/seat_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 7,
    title: 'Members-Only',
    desc: 'Rewards',
    url: '/icons/auto-fix.svg',
    link: '/icons/auto-fix_white.svg',
    link2: '/icons/auto-fix_brown.svg',
    width: '42px',
    height: '42px',
  },
  {
    id: 8,
    title: 'Full Access to',
    desc: 'NDTV Profit App',
    url: '/icons/google_playstore.svg',
    link: '/icons/google_playstore_white.svg',
    link2: '/icons/google_playstore_brown.svg',
    width: '73px',
    height: '32px',
  },
];

export const SUBSCRIBER_FEATURES_MOB = [
  {
    id: 1,
    title: 'Access to',
    desc: 'Bloomberg',
    url: '/icons/message_star.svg',
    link: '/icons/star_profit_white.svg',
    link2: '/icons/star_profit_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 2,
    title: 'Curated',
    desc: 'Newsletters',
    url: '/icons/email_lock.svg',
    link: '/icons/email_lock_white.svg',
    link2: '/icons/email_lock_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 3,
    title: '20,000+',
    desc: 'Research Reports',
    url: '/icons/file_chart.svg',
    link: '/icons/file_chart_white.svg',
    link2: '/icons/file_chart_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 4,
    title: 'Priority Pass',
    desc: 'to Special Events',
    url: '/icons/seat.svg',
    link: '/icons/seat_white.svg',
    link2: '/icons/seat_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 5,
    title: 'Ad-Lite',
    desc: 'Experience',
    url: '/icons/cancel.svg',
    link: '/icons/cancel_white.svg',
    link2: '/icons/cancel_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 6,
    title: 'Members-Only',
    desc: 'Rewards',
    url: '/icons/auto-fix.svg',
    link: '/icons/auto-fix_white.svg',
    link2: '/icons/auto-fix_brown.svg',
    width: '42px',
    height: '42px',
  },
  {
    id: 7,
    title: 'NDTV Profit',
    desc: 'Exclusive Stories',
    url: '/icons/star_profit.svg',
    link: '/icons/message_star_white.svg',
    link2: '/icons/message_star_brown.svg',
    width: '32px',
    height: '32px',
  },
  {
    id: 8,
    title: 'Full Access to',
    desc: 'NDTV Profit App',
    url: '/icons/google_playstore.svg',
    link: '/icons/google_playstore_white.svg',
    link2: '/icons/google_playstore_brown.svg',
    width: '73px',
    height: '32px',
  },
];

export const PRICING_DETAILS = [
  {
    id: 1,
    title: 'Are you a Student?',
    desc: '<span>Get 50% off</span> on NDTV Profit Student Subscription with a valid college ID',
    btnText: 'Get my Discount',
    url: '/icons/graduate.svg',
    imgURL: '/images/student_f.png',
    link: '#',
    icon: true,
  },
  {
    id: 2,
    title: 'Corporate Plans?',
    desc: 'Get Attractive offers with Corporate <br/>Bulk Subscription',
    desc_mb: 'Get Attractive offers with Corporate Bulk Subscription',
    btnText: 'I’m Interested',
    url: '/icons/corporate.svg',
    imgURL: '/images/student_m.png',
    link: '#',
    icon: true,
  },
  {
    id: 3,
    title: 'Gift A Subscription',
    desc: 'Coming Soon',
    btnText: '',
    url: '/icons/gift.svg',
    imgURL: '/images/student_f.png',
    link: '#',
    icon: false,
  },
];

export const SUBSCRIPTION_NAVBAR = [
  {
    id: 1,
    title: 'Student/ Corporate',
    link: 'bulk-deals',
  },
  {
    id: 2,
    title: 'FAQs',
    link: 'faqs',
  },
  {
    id: 3,
    title: 'Contact Us',
    link: 'footer',
  },
];

export const SUBSCRIPTIONGETMODAL = [
  {
    name: 'Profit Exclusive Stories',
    icon: '/icons/star_profit.svg',
  },
  {
    name: '20,000+ Research Reports',
    icon: '/icons/file_chart.svg',
  },
  {
    name: 'Ad-Lite Experience on all platforms',
    icon: '/icons/cancel.svg',
  },
  {
    name: 'Bloomberg Stories',
    icon: '/icons/message_star.svg',
  },
  {
    name: 'Priority Invites to NDTV Profit Events',
    icon: '/icons/seat.svg',
  },
  {
    name: 'Curated Newsletters',
    icon: '/icons/email_lock.svg',
  },
];

export const EXCLUSIVEFEATURES = [
  {
    headline: 'Accurate Data-Driven Stories',
    icon: true,
  },
  {
    headline: 'Timely & Actionable Insights',
    icon: true,
  },
  {
    headline: 'In-Depth Analysis. Guaranteed',
    icon: true,
  },
];

export const SUBSCRIPTION_REPORTS = [
  {
    name: '20,000+',
    title: 'Research Reports',
    link: '#',
    icon: '/icons/report_count.svg',
  },
  {
    name: '20+',
    title: 'Brokerage Houses',
    link: '#',
    icon: '/icons/report_broker.svg',
  },
  {
    name: 'Unlimited',
    title: 'Access to Report Archives',
    link: '#',
    icon: '/icons/report_archive.svg',
  },
];

export const SUBSCRIPTION_REPORTS_CARDS = [
  {
    cardColor: '#EFF8FF',
    txtColor: '#0672B7',
    link: 'https://www.ndtvprofit.com/author/1409438/anand-rathi',
    icon: '/icons/research_reports_brand/anand_rathi.png',
  },
  {
    cardColor: '#EFF8FF',
    txtColor: '#0672B7',
    link: 'https://www.ndtvprofit.com/author/1409437/angel-broking',
    icon: '/icons/research_reports_brand/angel_broking.png',
  },
  {
    cardColor: '#FFF4F4',
    txtColor: '#EA1C24',
    link: 'https://www.ndtvprofit.com/author/1409443/axis-securities',
    icon: '/icons/research_reports_brand/Axis.png',
  },
  {
    cardColor: '#CEF5F3',
    txtColor: '#008D62',
    link: 'https://www.ndtvprofit.com/author/1630354/careedge',
    icon: '/icons/research_reports_brand/care_edge.png',
  },
  {
    cardColor: '#CEF5F3',
    txtColor: '#008D62',
    link: 'https://www.ndtvprofit.com/author/1409444/centrum-broking',
    icon: '/icons/research_reports_brand/centrum.png',
  },
  {
    cardColor: '#EDF9FF',
    txtColor: '#1793CD',
    link: 'https://www.ndtvprofit.com/author/1919986/deven-choksey-research',
    icon: '/icons/research_reports_brand/deven_choksey_removebg_preview.png',
  },
  {
    cardColor: '#EFF8FF',
    txtColor: '#1793CD',
    link: 'https://www.ndtvprofit.com/author/1416722/dolat-capital',
    icon: '/icons/research_reports_brand/dolat_capital.png',
  },
  {
    cardColor: '#FFF4F4',
    txtColor: '#EA1C24',
    link: 'https://www.ndtvprofit.com/author/1409439/geojit-research',
    icon: '/icons/research_reports_brand/geojit.png',
  },
  {
    cardColor: '#E4F8F2',
    txtColor: '#008D62',
    link: 'https://www.ndtvprofit.com/author/1409430/icici-securities',
    icon: '/icons/research_reports_brand/Icici.png',
  },
  {
    cardColor: '#E4F8F2',
    txtColor: '#008D62',
    link: 'https://www.ndtvprofit.com/author/1445867/idbi-capital',
    icon: '/icons/research_reports_brand/idbi_bank_emblem.png',
  },
  {
    cardColor: '#EDF9FF',
    txtColor: '#0672B7',
    link: 'https://www.ndtvprofit.com/author/1409440/krchoksey-research',
    icon: '/icons/research_reports_brand/kr_removebg_preview.png',
  },
  {
    cardColor: '#F2FDBC',
    txtColor: '#384697',
    link: 'https://www.ndtvprofit.com/author/1409436/nirmal-bang-institutional-research',
    icon: '/icons/research_reports_brand/Nirmal_bang.png',
  },
  {
    cardColor: '#EDF9FF',
    txtColor: '#1793CD',
    link: 'https://www.ndtvprofit.com/author/1496892/prabhudas-lilladher',
    icon: '/icons/research_reports_brand/prabhudas_lilladher_removebg_preview.png',
  },
  {
    cardColor: '#FFF4F4',
    txtColor: '#EA1C24',
    link: 'https://www.ndtvprofit.com/author/1409442/reliance-securities',
    icon: '/icons/research_reports_brand/reliance_securities_logo_removebg_preview.png',
  },
  {
    cardColor: '#EDF9FF',
    txtColor: '#1793CD',
    link: 'https://www.ndtvprofit.com/author/1558273/systematix-institutional-equities',
    icon: '/icons/research_reports_brand/systematic_group.png',
  },
];

export const PREMIUM_SECTION_BTN = [
  {
    name: 'All Premium',
    id: 'premium',
  },
  {
    name: 'Exclusives',
    link: '/premium/exclusive',
  },
  {
    name: 'Profit Insights',
    link: '/premium/profit-insights',
  },
  {
    name: 'Research Reports',
    id: 'research-reports',
  },
  {
    name: 'Bloomberg',
    id: 'bloomberg',
  },
];

export const PODCAST_SOCIAL_LINK = [
  {
    src: '/icons/spotify_icon.svg',
    alt: 'Spotify icon',
    desktop: '38px',
    mobile: '32px',
    allYouKnow: 'https://open.spotify.com/show/3IONrlFq1WvhF6FyzX1KuD',
    bigDecision: 'https://open.spotify.com/show/2XPD0ADNwg21BRVRgFXHGN',
  },
  {
    src: '/icons/podcast.png',
    alt: 'Podcast icon',
    desktop: '38px',
    mobile: '32px',
    allYouKnow:
      'https://podcasts.apple.com/in/podcast/all-you-need-to-know-by-ndtv-profit/id1668527825',
    bigDecision: 'https://podcasts.apple.com/in/podcast/big-decisions-ndtv-profit/id1744912937',
  },
  {
    src: '/icons/amazon_music_black.svg',
    alt: 'Amazon Podcast icon',
    desktop: '38px',
    mobile: '32px',
    allYouKnow:
      'https://music.amazon.in/podcasts/5c619ec0-244c-4845-9e15-378237b90192/all-you-need-to-know-by-ndtv-profit',
    bigDecision:
      'https://music.amazon.in/podcasts/083cce18-f967-4ece-aec0-9acfd1b945af/big-decisions-ndtv-profit',
  },
  {
    src: '/icons/Hubhopper_icon.svg',
    alt: 'Hubhopper icon',
    desktop: '38px',
    mobile: '32px',
    allYouKnow: 'https://hubhopper.com/podcast/all-you-need-to-know-by-ndtv-profit/414759',
    bigDecision: 'https://hubhopper.com/podcast/big-decisions-ndtv-profit/441317',
  },
];
